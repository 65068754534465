import React from "react"
import Bandeau from "../components/bandeau"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Image from 'react-bootstrap/Image'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const GunnarPassePartout = () => (

    <Bandeau title="Passe-Partouts">
        <Row >
            <Col>
                <b>Je peux vous découper tout type de passe-partouts.</b>
                <br></br><br></br>
                <p>Des formes les plus classiques...</p>
            </Col>
        </Row>
        <Row className="align-items-center m-3">
            <Col>
                <StaticImage
                    src='../images/pps/multi_1.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/pps/multi_2.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
        </Row>
        <Row className="align-items-center m-3">
            <Col>
                <StaticImage
                    src='../images/pps/multi_3.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/pps/multi_4.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
        </Row>

        <Row >
            <Col>
                <p>... aux plus originales !</p>

            </Col>
        </Row>

        <Row className="align-items-center m-3">
            <Col>
                <StaticImage
                    src='../images/pps/orig_1.jpg'
                    alt="First slide"
                    className='roundedPict m-3'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/pps/orig_2.jpg'
                    alt="First slide"
                    className='roundedPict m-3'
                />
            </Col>
            {/* <Col>
                <StaticImage
                    src='../images/pps/orig_3.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col> */}
            <Col>
                <StaticImage
                    src='../images/pps/orig_4.jpg'
                    alt="First slide"
                    className='roundedPict m-3'
                />
            </Col>
        </Row>

        <Row >
            <Col>
                <p><Link className='link' to="/contact">Contactez-moi</Link> si vous souhaitez plus de renseignements. Je peux réaliser tout type de forme. </p>
            </Col>
        </Row>
    </Bandeau>

)
export default GunnarPassePartout
