import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GunnarExemples from "../containers/gunnar-exemples"
import GunnarMachine from "../containers/gunnar-machine"
import GunnarPassePartout from "../containers/gunnar-passepartout"


const GunnarPage = () => (
  <Layout>
    <SEO title="Gunnar" />
    <br/><br/>
    <GunnarMachine/>
    <GunnarPassePartout/>
    <GunnarExemples/>
  </Layout>

)
export default GunnarPage
