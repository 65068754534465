import React from "react"
import Bandeau from "../components/bandeau"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const GunnarExemples = () => (

    <Bandeau title="Galerie" dark>
        <Row className="align-items-center mt-4">
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_4.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_3.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_1.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_2.png'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
        </Row>

        <Row className="align-items-center mt-4">
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_5.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_6.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_7.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
            <Col>
                <StaticImage
                    src='../images/gunnar_pps_8.jpg'
                    alt="First slide"
                    className='roundedPict'
                />
            </Col>
        </Row>


    </Bandeau>

)
export default GunnarExemples
