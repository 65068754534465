import React from "react"
import Bandeau from "../components/bandeau"

import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"

const GunnarMachine = () => (
  <Bandeau title="Machine Gunnar" dark>

    <Row className='mt-5'>
      <Col>
        <Carousel className="d-block carouselCarton roundedPict">
          <Carousel.Item className='roundedPict'>
            <StaticImage
              src='../images/machine_gunnard.jpg'
              alt="First slide"
              className='roundedPict'
            />

          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src='../images/machine_gunnard_1.jpg'
              alt="First slide"
              className='roundedPict'

            />

          </Carousel.Item>
        </Carousel>
      </Col>
      <Col>
        <ul className="justified">
          <li>Permet d’accéder à de nombreuses possibilités d’encadrement</li>
          <li>Offre une multitude de créations de passe-partouts originaux </li>
          <li>
            De créer des boîtes d’archives, de rangement de toutes sortes permettant
            ainsi un rangement optimal et parfaitement adapté à vos besoins
            (collectionneur, bricolage, architecte, notaire, aménagement des
            armoires)
          </li>
          <li>De mettre en valeur des photos de manière inattendue</li>
          <li>
            De créer des décorations surprenantes et originales pour décorer des
            salles de mariages, des soirées anniversaires, des départs en retraites
           </li>
          <li>Créer à l’infini des pêles-mêles classiques ou originaux </li>
        </ul>
      </Col>
    </Row>

    <Row className='mt-5'>
      <p>
        <b>Sur Rendez Vous uniquement</b> : je vous accueille le soir jusqu’à 19h30.
        {/* <br></br>
        J’assure les cours de cartonnage à
        l’atelier ; il est de ce fait très important pour moi et le groupe de ne
        pas être dérangés pendant les cours.
        <br></br> */}

    </p>


    </Row>






  </Bandeau>
)
export default GunnarMachine
